<template>
    <div>
        <relationMatchList :game_id="game_id"></relationMatchList>
    </div>
</template>
<script>

import relationMatchList from '@/components/relation/games/match/list.vue';

export default {
    components: {
        relationMatchList
    },
    data () {
        let self = (window.x = this);
        return {
            game_id: 1,
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
